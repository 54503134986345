import { WindowLocation } from '@reach/router';
import { useEffect } from 'react';
import { useSetAtom } from 'jotai';

import { useAppContext } from '@contexts/AppContext';
import { AbsPage } from '@src/modules/AnnualBoilerService/Abs';
import { isAgentAtom, isHomeRecoverAtom } from '@src/store/store';

interface Props {
  location: WindowLocation;
}

const AbsAgent = (props: Props) => {
  const { verifyNoSignupInLocalStorage } = useAppContext();
  const setIsHomeRecover = useSetAtom(isHomeRecoverAtom);
  const setIsAgent = useSetAtom(isAgentAtom);

  useEffect(() => {
    verifyNoSignupInLocalStorage();
    setIsHomeRecover(false);
    setIsAgent(true);
  }, [verifyNoSignupInLocalStorage]);

  return <AbsPage {...props} />;
};

export default AbsAgent;
